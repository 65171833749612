@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Climate+Crisis&family=Edu+NSW+ACT+Foundation:wght@700&family=Nunito&family=Roboto&display=swap');

body {
background-image: url('../assets/portfolio_background.gif');
background-size: cover;
}

h1 {
  margin: 10px 0px 2px 0px;
  font-family: 'Nunito', Arial, Helvetica, sans-serif;
  font-weight: bold;
  font-size: 36px;
  color: white;
}

h2 {
  margin: 10px 0px 2px 0px;
  font-family: 'Nunito', Arial, Helvetica, sans-serif;
  font-weight: bold;
  font-size: x-large;
  color: white;
}

h3 {
  font-style: italic;
  font-family: 'Nunito', Arial, Helvetica, sans-serif;
  color: skyblue;
}

.font-nunito {
  font-family: 'Nunito', Arial, Helvetica, sans-serif;
  font-weight: 400;
}

.prose img {
    margin-left: auto;
    margin-right: auto;
    border-radius: 5px;
}